import axios from "axios";

console.log(process.env);
const API_URL_BUS_INTEGRATION = process.env.REACT_APP_BUSINTEGRATION_URL;

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

export const getHealth = () => {
  return axios.get(`${API_URL_BUS_INTEGRATION}/busintegration/health`, { headers });
};

export const postSearchStateLead = async (run, dv) => {
  const params = new URLSearchParams();
  params.append('run', run);
  params.append('dv', dv);

  return axios.post(`${API_URL_BUS_INTEGRATION}/busintegration/search_state_lead`, params, { headers });
};