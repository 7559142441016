import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { formatRutWithoutDots } from "./rutFormatter";
import '../css/buscarLeadForm.css'

import { postSearchStateLead } from './busIntegrationServices'

const BuscarLeadSchema = Yup.object().shape({
    rut: Yup.string()
        .min(9, 'Too Short!')
        .max(12, 'Too Long!')
        .required('Required')
});

class BuscarLeadForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rut: '',
            response: {}
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Buscar State Lead</h1>
                <br />
                <Formik
                    initialValues={{ rut: '', mensage: '' }}
                    validationSchema={BuscarLeadSchema}
                    onSubmit={(values) => {
                        const formatRutWithDot = formatRutWithoutDots(values.rut);
                        const rutFormat = formatRutWithDot.split('-');

                        postSearchStateLead(rutFormat[0], rutFormat[1]).then((res) => {
                            const data = res['data'];
                            let response = {};
                            if (data['ok']) {
                                response = {
                                    ok: data['ok'],
                                    menssage: data['statusLead']                                    
                                }
                            } else {
                                response = {
                                    ok: data['ok'],
                                    menssage: data['err']
                                };
                            }
                            this.setState({response});

                        }).catch((err) => {
                            console.log(err);
                        });
                    }}
                >
                    {({ values, errors, touched, handleSubmit, handleChange }) => (
                        <Form onSubmit={handleSubmit}>

                            { this.state.response.menssage && !errors.rut ? (
                                <div className="alert alert-success">
                                    {this.state.response.menssage}
                                </div>
                            ) : null}

                            {errors.rut && touched.rut && (
                                <div className="alert alert-danger">{errors.rut}</div>
                            )}

                            <div className="form-group">
                                <label>Rut</label>
                                <input 
                                    name="rut" 
                                    type="text"
                                    value={values.rut}
                                    placeholder="1.111.111-1"
                                    onChange={e => {
                                        this.state.response.menssage = '';
                                        handleChange(e);
                                    }}
                                    className={'form-control' + (errors.rut && touched.rut ? ' is-invalid' : '')} />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Buscar</button>
                            </div>


                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default BuscarLeadForm;